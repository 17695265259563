import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';

const SubscriptionPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const initiateStripeCheckout = async () => {
      try {
        if (!auth.currentUser) {
          setError("Usuário não autenticado. Redirecionando para o login...");
          setLoading(false);
          setTimeout(() => navigate("/"), 3000);
          return;
        }

        const userId = auth.currentUser.uid;

        const response = await fetch('https://songmetrix.com.br:8001/create-checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId }),
        });

        if (!response.ok) {
          throw new Error(`Erro no servidor: ${response.status} - ${response.statusText}`);
        }

        const session = await response.json();
        if (session.url) {
          window.location.href = encodeURIComponent(session.url);
        } else {
          throw new Error("URL da sessão não encontrada.");
        }
      } catch (error) {
        console.error("Erro ao iniciar o Stripe Checkout:", error);
        setError("Erro ao iniciar o pagamento. Tente novamente mais tarde.");
      } finally {
        setLoading(false);
      }
    };

    initiateStripeCheckout();
  }, [navigate]);

  return (
    <div>
      <h1>Assine Agora</h1>
      {loading && <p>Redirecionando para a página de pagamento...</p>}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default SubscriptionPage;
