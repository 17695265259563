import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import AppWrapper from './AppWrapper';
import reportWebVitals from './reportWebVitals';

console.log("Index.js está sendo executado");

const root = createRoot(document.getElementById('root'));

console.log("Root criado");

root.render(
  <React.StrictMode>
    <Router>
      <AppWrapper />
    </Router>
  </React.StrictMode>
);

console.log("Render chamado");

reportWebVitals();
