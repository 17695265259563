import React from 'react';
import { useNavigate } from 'react-router-dom';
import App from './App';

function AppWrapper() {
  console.log("AppWrapper está sendo renderizado");
  const navigate = useNavigate();
  return (
    <div>
      <App navigate={navigate} />
    </div>
  );
}

export default AppWrapper;
