import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import "./RealTime.css";
import { db } from './firebase';
import { collection, getDocs } from 'firebase/firestore';

const RealTime = () => {
  const [filters, setFilters] = useState({
    radio: "Todas as Rádios",
    artist: "",
    song: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: ""
  });
  const [appliedFilters, setAppliedFilters] = useState(filters);
  const [allData, setAllData] = useState([]);
  const [radios, setRadios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [excludedSongs, setExcludedSongs] = useState([]);
  const containerRef = useRef(null);
  const pageSize = 100;

  const fetchAllRadios = async () => {
    try {
      const response = await fetch("/api/getAllRadios", {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0"
        }
      });
      const data = await response.json();
      setRadios(data);
    } catch (error) {
      console.error("Erro ao buscar rádios:", error);
    }
  };

  const fetchExcludedSongs = async () => {
    try {
      const excludedSongsSnapshot = await getDocs(collection(db, 'excludedSongs'));
      const excludedSongsData = excludedSongsSnapshot.docs.map(doc => doc.data());
      setExcludedSongs(excludedSongsData);
    } catch (error) {
      console.error("Erro ao buscar músicas excluídas:", error);
    }
  };

  const removeDuplicates = (data) => {
    const seen = new Set();
    return data.filter((item) => {
      const key = `${item.song_title}-${item.artist}-${item.time}`;
      if (!seen.has(key)) {
        seen.add(key);
        return true;
      }
      return false;
    });
  };

  const fetchData = async (page = 1, filtersParam = appliedFilters) => {
    if (isLoading || !hasMoreData) return;

    setIsLoading(true);

    try {
      const paramsObj = { page, pageSize };

      if (filtersParam.radio && filtersParam.radio !== "Todas as Rádios") {
        paramsObj.radio = filtersParam.radio;
      }
      if (filtersParam.artist) {
        paramsObj.artist = filtersParam.artist;
      }
      if (filtersParam.song) {
        paramsObj.song = filtersParam.song;
      }
      if (filtersParam.startDate) {
        paramsObj.startDate = filtersParam.startDate;
      }
      if (filtersParam.endDate) {
        paramsObj.endDate = filtersParam.endDate;
      }
      if (filtersParam.startTime) {
        paramsObj.startTime = filtersParam.startTime;
      }
      if (filtersParam.endTime) {
        paramsObj.endTime = filtersParam.endTime;
      }

      const params = new URLSearchParams(paramsObj);

      const response = await fetch(`/api/getMusicLog?${params.toString()}`, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0"
        }
      });

      if (!response.ok) {
        console.error(`Erro na requisição: ${response.status} ${response.statusText}`);
        setHasMoreData(false);
        return;
      }

      const data = await response.json();

      if (Array.isArray(data) && data.length === 0) {
        setHasMoreData(false);
      } else if (Array.isArray(data)) {
        const filteredData = removeDuplicates(data);
        setAllData((prevData) => [...prevData, ...filteredData]);
      } else {
        setHasMoreData(false);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      setHasMoreData(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadMore = () => {
    if (hasMoreData) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const applyFilters = (data) => {
    return data.filter((item) => {
      // Verifica se a música não está na lista de excluídas
      const isNotExcluded = !excludedSongs.some(
        excludedSong => 
          excludedSong.artist.toLowerCase() === item.artist.toLowerCase() &&
          excludedSong.title.toLowerCase() === item.song_title.toLowerCase()
      );

      if (!isNotExcluded) return false;

      const itemDate = item.date ? item.date.split("T")[0] : "";
      const itemTime = item.time || "";

      const matchesRadio = appliedFilters.radio === "Todas as Rádios" || item.name === appliedFilters.radio;
      const matchesSongTitle = !appliedFilters.song || (item.song_title && item.song_title.toLowerCase().includes(appliedFilters.song.toLowerCase()));
      const matchesArtistName = !appliedFilters.artist || (item.artist && item.artist.toLowerCase().includes(appliedFilters.artist.toLowerCase()));
      const matchesDate = (!appliedFilters.startDate || !appliedFilters.endDate) || (itemDate >= appliedFilters.startDate && itemDate <= appliedFilters.endDate);
      const matchesHour = (!appliedFilters.startTime || !appliedFilters.endTime) || (itemTime >= appliedFilters.startTime && itemTime <= appliedFilters.endTime);

      return matchesRadio && matchesSongTitle && matchesArtistName && matchesDate && matchesHour;
    });
  };

  const handleFilter = () => {
    setAllData([]);
    setCurrentPage(1);
    setHasMoreData(true);
    setAppliedFilters(filters);
  };

  useEffect(() => {
    fetchAllRadios();
    fetchExcludedSongs();
    setAllData([]);
    setCurrentPage(1);
    setHasMoreData(true);
  }, []);

  useEffect(() => {
    fetchData(currentPage, appliedFilters);
  }, [currentPage, appliedFilters]);

  return (
    <div className="real-time-content full-height" ref={containerRef}>
      <header style={{ backgroundColor: 'transparent', color: '#333', padding: '20px' }}>
        <h1>Execuções em Tempo Real</h1>
      </header>
      <div className="filters">
        <div className="filter-row">
          <div className="filter-group">
            <label htmlFor="radio-select">Rádio:</label>
            <select
              id="radio-select"
              value={filters.radio}
              onChange={(e) => setFilters({ ...filters, radio: e.target.value })}
            >
              <option value="Todas as Rádios">Todas as Rádios</option>
              {radios.map((radio) => (
                <option key={radio} value={radio}>
                  {radio}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-group">
            <label htmlFor="artist-name">Artista:</label>
            <input
              type="text"
              id="artist-name"
              value={filters.artist}
              onChange={(e) => setFilters({ ...filters, artist: e.target.value })}
              placeholder="Digite o nome do artista"
            />
          </div>
          <div className="filter-group">
            <label htmlFor="song-title">Música:</label>
            <input
              type="text"
              id="song-title"
              value={filters.song}
              onChange={(e) => setFilters({ ...filters, song: e.target.value })}
              placeholder="Digite o título da música"
            />
          </div>
        </div>

        <div className="filter-row-datetime">
          <div className="filter-group">
            <label htmlFor="date-start">Data Início:</label>
            <input
              type="date"
              id="date-start"
              value={filters.startDate}
              onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
            />
          </div>
          <div className="filter-group">
            <label htmlFor="date-end">Data Fim:</label>
            <input
              type="date"
              id="date-end"
              value={filters.endDate}
              onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
            />
          </div>
          <div className="filter-group">
            <label htmlFor="time-start">Hora Início:</label>
            <input
              type="time"
              id="time-start"
              value={filters.startTime}
              onChange={(e) => setFilters({ ...filters, startTime: e.target.value })}
            />
          </div>
          <div className="filter-group">
            <label htmlFor="time-end">Hora Fim:</label>
            <input
              type="time"
              id="time-end"
              value={filters.endTime}
              onChange={(e) => setFilters({ ...filters, endTime: e.target.value })}
            />
          </div>
        </div>

        <button
          className="btn-primary"
          onClick={handleFilter}
        >
          PESQUISAR
        </button>
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Data</th>
              <th>Hora</th>
              <th>Rádio</th>
              <th>Artista</th>
              <th>Título</th>
              <th>ISRC</th>
              <th>Cidade</th>
              <th>Estado</th>
              <th>Gênero</th>
            </tr>
          </thead>
          <tbody>
            {applyFilters(allData).map((item, index) => (
              <tr key={index}>
                <td>{item.date ? moment.utc(item.date).format("DD/MM/YYYY") : "Data não disponível"}</td>
                <td>{item.time || "Hora não disponível"}</td>
                <td>{item.name || "Emissora não disponível"}</td>
                <td>{item.artist || "Artista não disponível"}</td>
                <td>{item.song_title || "Título não disponível"}</td>
                <td>{item.isrc || "ISRC não disponível"}</td>
                <td>{item.cidade || "Cidade não disponível"}</td>
                <td>{item.estado || "Estado não disponível"}</td>
                <td>{item.genre || "Gênero não disponível"}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading && (
          <div className="loading">Carregando dados...</div>
        )}
      </div>

      <div className="pagination-controls">
        <button
          disabled={!hasMoreData}
          onClick={handleLoadMore}
          className="btn-primary"
        >
          Carregar Mais
        </button>
      </div>
    </div>
  );
};

export default RealTime;
