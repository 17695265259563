import React, { useState, useEffect } from "react";
import "./Dashboard.css";

console.log('REACT_APP_API_BASE_URL:', process.env.REACT_APP_API_BASE_URL);
const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://songmetrix.com.br/ranking';

const Dashboard = () => {
  const [radiosMonitoradas, setRadiosMonitoradas] = useState(0);
  const [tendenciasAlta, setTendenciasAlta] = useState("");
  const [musicaQueda, setMusicaQueda] = useState("");
  const [rankingMusicas, setRankingMusicas] = useState([]);
  const [topMusicas, setTopMusicas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

const fetchData = async (endpoint, setter, dataProcessor) => {
  const url = `${BASE_URL}/ranking${endpoint}`;
  console.log(`Fetching: ${url}`);
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log(`Response for ${endpoint}:`, response);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    console.log(`Data for ${endpoint}:`, data);
    setter(dataProcessor ? dataProcessor(data) : data);
  } catch (error) {
    console.error(`Error fetching ${endpoint}:`, error);
    throw error;
  }
};



console.log('Environment:', process.env.NODE_ENV);
console.log('REACT_APP_API_BASE_URL:', process.env.REACT_APP_API_BASE_URL);
console.log('Calculated BASE_URL:', BASE_URL);


 useEffect(() => {
  const fetchAllData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      console.log('Iniciando fetchData no Dashboard');
      console.log('BASE_URL:', BASE_URL);
      
      await Promise.all([
        fetchData('/getMonitoredRadios', setRadiosMonitoradas, data => data.total_radios),
        fetchData('/getTrendingUpMusic', setTendenciasAlta, data => `${data.artist} - ${data.song_title}`),
        fetchData('/getTrendingDownMusic', setMusicaQueda, data => {
          if (data.length > 0) {
            const ultimaSemana = data[data.length - 1];
            return `Semana ${ultimaSemana.week}: ${ultimaSemana.executions} execuções`;
          }
          return "Nenhuma tendência de queda identificada";
        }),
        fetchData('/getRankingData?days=7&rankingSize=5', setRankingMusicas, data => 
          data.map(item => `${item.artist} - ${item.song_title}`)
        ),
        fetchData('/getTopSongs?days=7&limit=10', setTopMusicas, data => 
          data.map(item => `${item.artist} - ${item.song_title}`)
        ),
      ]);
      console.log('Todas as chamadas de API concluídas com sucesso');
    } catch (error) {
      console.error("Erro detalhado ao carregar dados do dashboard:", error);
      setError(`Falha ao carregar dados do dashboard: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  fetchAllData();
}, []);


  if (isLoading) {
    return <div className="loading">Carregando...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-top-bar">
        <nav className="dashboard-menu">
          <span className="menu-item active">Visão Geral</span>
          <span className="menu-item">Desempenho</span>
          <span className="menu-item">Metas</span>
        </nav>
        <button className="dashboard-create-btn">+ Criar Novo</button>
      </div>

      <div className="dashboard-content">
        <div className="dashboard-card dashboard-large">
          <h3>Radios Monitoradas</h3>
          <p>{radiosMonitoradas}</p>
        </div>
        <div className="dashboard-card dashboard-small">
          <h3>Tendências de Alta</h3>
          <p>{tendenciasAlta}</p>
        </div>
        <div className="dashboard-card dashboard-small">
          <h3>Música em Tendência de Queda</h3>
          <p>{musicaQueda}</p>
        </div>
        <div className="dashboard-card dashboard-small">
          <h3>Ranking de Músicas Mais Tocadas</h3>
          <ul>
            {rankingMusicas.map((musica, index) => (
              <li key={index}>{musica}</li>
            ))}
          </ul>
        </div>
        <div className="dashboard-card dashboard-large">
          <h3>As 10 Músicas Mais Executadas</h3>
          <ul>
            {topMusicas.map((musica, index) => (
              <li key={index}>{musica}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
