import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from "firebase/firestore";
import RealTime from './RealTime';
import Ranking from './Ranking';
import Dashboard from './Dashboard';
import EmailVerification from './EmailVerification';
import ResetPassword from './ResetPassword';
import Admin from './Admin';
import { FaHome, FaChartLine, FaTrophy, FaSignOutAlt, FaHeadset } from "react-icons/fa";
import Footer from './Footer';
import { auth, db } from './firebase';
import "./App.css";
import "./Login.css";
import SubscriptionPage from './SubscriptionPage';

function App() {
  const navigate = useNavigate(); 
  const [authStatus, setAuthStatus] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const checkUserStatus = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsAdmin(userData.isAdmin || false);
        setSubscriptionStatus(userData.status || "pendente");
      } else {
        setIsAdmin(false);
        setSubscriptionStatus("pendente");
      }
    } catch (error) {
      console.error("Erro ao verificar status de usuário: ", error);
    }
  };

useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, async (user) => {
    if (user) {
      setCurrentUser(user);
      setIsAuthenticated(user.emailVerified);
      setAuthStatus(`Usuário logado: ${user.email}`);
      if (!user.emailVerified) {
        setAuthStatus("Por favor, verifique seu e-mail para ativar sua conta.");
      }
      await checkUserStatus(user.uid);

      // Redirecionar somente se não for admin e estiver com assinatura pendente
      if (!isAdmin && subscriptionStatus === "pendente") {
        navigate("/assine-agora");
      }
    } else {
      setIsAuthenticated(false);
      setAuthStatus("");
      setCurrentUser(null);
      setIsAdmin(false);
    }
  });

  return () => unsubscribe();
}, [navigate, subscriptionStatus, isAdmin]);


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setLoading(false);
      navigate("/real-time");
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      setAuthStatus("E-mail ou senha incorretos. Tente novamente.");
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setAuthStatus("Logout realizado com sucesso!");
      setIsAuthenticated(false);
      setCurrentUser(null);
      setIsAdmin(false);
      navigate("/");
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
      setAuthStatus(`Erro ao fazer logout: ${error.message}`);
    }
  };

  return (
    <div className="app-container">
      {!isAuthenticated ? (
        <div className="login-container">
          <h2>Login</h2>
          <form onSubmit={handleLogin}>
            <div className="input-group">
              <label>Email:</label>
              <input type="email" name="email" required />
            </div>
            <div className="input-group">
              <label>Senha:</label>
              <input type="password" name="password" required />
            </div>
            <button type="submit" className="btn-primary">
              {loading ? "Carregando..." : "Entrar"}
            </button>
          </form>
          {authStatus && <p className="auth-status">{authStatus}</p>}
          <div className="signup-link">
            <p>Não tem acesso?</p>
            <Link to="/assine-agora" className="btn-signup">
              Crie sua conta
            </Link>
          </div>
        </div>
      ) : (
        <div className="app-content">
          <nav className="modern-menu">
            <div className="logo">SongMetrix</div>
            <div className="hamburger" onClick={toggleMenu}>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className={`menu-items ${menuActive ? "active" : ""}`}>
              <Link to="/"><FaHome /> Home</Link>
              <Link to="/real-time"><FaChartLine /> Real Time</Link>
              <Link to="/ranking"><FaTrophy /> Ranking</Link>
              {isAdmin && (
                <Link to="/admin"><FaHeadset /> Admin</Link>
              )}
              <a href="https://wa.me/5527997101531" target="_blank" rel="noopener noreferrer"><FaHeadset /> Suporte</a>
            </div>
            <button className="logout-btn" onClick={handleLogout}>
              <FaSignOutAlt /> Logout
            </button>
          </nav>
          <main className="main-content">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/real-time" element={<RealTime />} />
              <Route path="/ranking" element={<Ranking />} />
              <Route path="/email-verification" element={<EmailVerification />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              {isAdmin && (
                <Route path="/admin" element={<Admin />} />
              )}
              <Route path="/assine-agora" element={<SubscriptionPage />} />
            </Routes>
          </main>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default App;
