import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("E-mail de redefinição de senha enviado. Verifique sua caixa de entrada.");
    } catch (error) {
      setMessage(`Erro ao enviar e-mail: ${error.message}`);
    }
  };

  return (
    <div className="reset-password-container">
      <h2>Redefinir Senha</h2>
      <form onSubmit={handleResetPassword}>
        <input
          type="email"
          placeholder="Digite seu e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Enviar E-mail de Redefinição</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default ResetPassword;
