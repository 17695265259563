import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import moment from "moment";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "./Ranking.css";
import { db } from './firebase';
import { collection, getDocs } from 'firebase/firestore';

const NODE_ENV = process.env.REACT_APP_NODE_ENV || 'development';
const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://songmetrix.com.br/ranking';

// Função para obter o token da API do Spotify
const fetchSpotifyToken = async () => {
  const clientId = "6454790e98c04c22b3fecc25dcd9e75c";
  const clientSecret = "b9d28568c7e04ad79735bf8fddb750ed";
  const tokenUrl = "https://accounts.spotify.com/api/token";

  try {
    const response = await fetch(tokenUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Basic " + btoa(clientId + ":" + clientSecret),
      },
      body: "grant_type=client_credentials",
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      console.error(`Erro ao buscar token do Spotify: ${response.status} - ${errorDetails.error.message}`);
      return null;
    }

    const data = await response.json();
    console.log("Token Spotify obtido com sucesso:", data.access_token);
    return data.access_token;
  } catch (error) {
    console.error("Erro ao buscar o token do Spotify:", error);
    return null;
  }
};

// Função para ajustar e limpar o nome do artista para a busca no Spotify
const cleanArtistName = (artistName) => {
  let cleanedName = artistName.replace(/&/g, "and").replace(/\se\s/g, " ");
  cleanedName = cleanedName.normalize("NFD").replace(/[̀-ͯ]/g, ""); // Remove acentos
  return encodeURIComponent(cleanedName.trim());
};

// Função para buscar a imagem do artista no Spotify
const fetchArtistImageFromSpotify = async (artistName, accessToken) => {
  if (!accessToken) {
    console.error("Token Spotify inválido ou não disponível.");
    return null;
  }

  const cleanedArtistName = cleanArtistName(artistName);
  const searchUrl = `https://api.spotify.com/v1/search?q=${cleanedArtistName}&type=artist&limit=1`;

  try {
    const response = await fetch(searchUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      console.error(`Erro ao buscar imagem para ${artistName}: ${response.status} - ${errorDetails.error.message}`);
      return null;
    }

    const data = await response.json();
    if (data.artists && data.artists.items.length > 0) {
      const artist = data.artists.items[0];
      return artist.images.length > 0 ? artist.images[0].url : null;
    } else {
      console.warn(`Nenhum artista encontrado para ${artistName}`);
    }
  } catch (error) {
    console.error(`Erro ao buscar imagem para o artista ${artistName}:`, error);
  }

  return null;
};

const Ranking = () => {
  const [rankingSize, setRankingSize] = useState("10");
  const [selectedRadios, setSelectedRadios] = useState([]);
  const [dateStart, setDateStart] = useState(moment().subtract(10, "days").format("YYYY-MM-DD"));
  const [dateEnd, setDateEnd] = useState(moment().format("YYYY-MM-DD"));
  const [hourStart, setHourStart] = useState("");
  const [hourEnd, setHourEnd] = useState("");
  const [rankingData, setRankingData] = useState([]);
  const [radiosOptions, setRadiosOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [artistImages, setArtistImages] = useState(() => {
    const cached = localStorage.getItem('artistImages');
    return cached ? JSON.parse(cached) : {};
  });
  const [excludedSongs, setExcludedSongs] = useState([]);
  const [spotifyTokenData, setSpotifyTokenData] = useState(() => {
    const storedToken = localStorage.getItem('spotifyToken');
    const storedExpiration = localStorage.getItem('spotifyTokenExpiration');
    if (storedToken && storedExpiration && Date.now() < parseInt(storedExpiration)) {
      return { token: storedToken, expiresAt: parseInt(storedExpiration) };
    }
    return null;
  });
  const [errorMessage, setErrorMessage] = useState(null);

  // Função para carregar e gerenciar o token do Spotify
  const loadSpotifyToken = useCallback(async () => {
    if (spotifyTokenData && spotifyTokenData.expiresAt > Date.now()) {
      return spotifyTokenData.token;
    }

    console.log("Obtendo novo token do Spotify...");
    const newToken = await fetchSpotifyToken();
    if (newToken) {
      const expiresAt = Date.now() + 3600000; // Token expira em 1 hora
      setSpotifyTokenData({ token: newToken, expiresAt });
      localStorage.setItem('spotifyToken', newToken);
      localStorage.setItem('spotifyTokenExpiration', expiresAt.toString());
      return newToken;
    } else {
      console.error("Falha ao obter token do Spotify.");
      return null;
    }
  }, [spotifyTokenData]);

  // Função para carregar imagens dos artistas
  const loadArtistImages = useCallback(async (data) => {
    const token = await loadSpotifyToken();
    if (!token) {
      console.error("Token Spotify não disponível. Imagens não podem ser carregadas.");
      return;
    }

    const newImages = {};
    for (const item of data) {
      if (!artistImages[item.artist]) {
        try {
          const spotifyImageUrl = await fetchArtistImageFromSpotify(item.artist, token);
          if (spotifyImageUrl) {
            newImages[item.artist] = spotifyImageUrl;
          }
        } catch (error) {
          console.error(`Erro ao carregar a imagem para ${item.artist}:`, error);
        }
      }
    }

    const updatedImages = { ...artistImages, ...newImages };
    setArtistImages(updatedImages);
    
    // Salvando o cache atualizado no localStorage
    localStorage.setItem('artistImages', JSON.stringify(updatedImages));
  }, [artistImages, loadSpotifyToken]);

  const fetchFilters = async () => {
    try {
      const response = await fetch(`${BASE_URL}/ranking/getFilters`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const radioOptions = data.radios.map((radio) => ({ value: radio, label: radio }));
      setRadiosOptions(radioOptions);
    } catch (error) {
      console.error("Erro ao carregar os filtros:", error);
    }
  };

  const fetchExcludedSongs = async () => {
    try {
      const excludedSongsSnapshot = await getDocs(collection(db, 'excludedSongs'));
      const excludedSongsData = excludedSongsSnapshot.docs.map(doc => doc.data());
      setExcludedSongs(excludedSongsData);
    } catch (error) {
      console.error("Erro ao buscar músicas excluídas:", error);
    }
  };

 const fetchRanking = useCallback(async () => {
  setIsLoading(true);
  setErrorMessage(null);
  try {
    const selectedRadioValues = selectedRadios.map((radio) => radio.value);
    const params = new URLSearchParams({
      days: moment(dateEnd).diff(moment(dateStart), 'days'),
      radios: selectedRadioValues.length > 0 ? selectedRadioValues.join(",") : "",
      startDate: dateStart,
      endDate: dateEnd,
      hourStart: hourStart || "",
      hourEnd: hourEnd || "",
      rankingSize: parseInt(rankingSize, 10)
    });

    console.log("Parâmetros enviados para a API:", params.toString());

    const response = await fetch(`${BASE_URL}/ranking/getRankingData?${params.toString()}`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(`Dados recebidos da API: ${data.length} itens`);
    console.log("rankingSize:", rankingSize);

    // Filtra as músicas excluídas
    const filteredData = data.filter(item => {
      return !excludedSongs.some(excludedSong =>
        excludedSong.artist.toLowerCase() === item.artist.toLowerCase() &&
        excludedSong.title.toLowerCase() === item.song_title.toLowerCase()
      );
    });

    console.log(`Dados após filtragem: ${filteredData.length} itens`);

    // Limita ao tamanho do ranking selecionado
    const limitedData = filteredData.slice(0, parseInt(rankingSize, 10));
    console.log(`Dados limitados ao ranking: ${limitedData.length} itens`);

    setRankingData(limitedData);

    // Carrega imagens apenas para os itens que serão exibidos
    await loadArtistImages(limitedData);
  } catch (error) {
    console.error("Erro ao carregar o ranking:", error);
    setErrorMessage("Não foi possível carregar o ranking. Por favor, tente novamente mais tarde.");
  } finally {
    setIsLoading(false);
  }
}, [rankingSize, selectedRadios, dateStart, dateEnd, hourStart, hourEnd, excludedSongs, loadArtistImages]);

// Inicialização única do componente
useEffect(() => {
  let isMounted = true;

  const initializeComponent = async () => {
    try {
      await Promise.all([
        fetchFilters(),
        loadSpotifyToken(),
        fetchExcludedSongs()
      ]);

      if (isMounted) {
        // Carrega o ranking inicial apenas uma vez
        await fetchRanking();
      }
    } catch (error) {
      console.error("Erro na inicialização:", error);
    }
  };

  initializeComponent();

  // Cleanup function
  return () => {
    isMounted = false;
  };
}, []); // Array de dependências vazio para executar apenas uma vez

// Adicione este useEffect para monitorar mudanças no rankingSize
useEffect(() => {
  console.log("rankingSize alterado para:", rankingSize);
}, [rankingSize]);

// Remova qualquer outro useEffect que possa estar causando chamadas extras à API


  const handleSearch = () => {
    fetchRanking();
  };

  const clearFilters = () => {
    setSelectedRadios([]);
    setDateStart(moment().subtract(10, "days").format("YYYY-MM-DD"));
    setDateEnd(moment().format("YYYY-MM-DD"));
    setHourStart("");
    setHourEnd("");
    setRankingSize("10");
  };

  return (
    <div className="ranking-container">
      <header>
        <h1>Ranking de Músicas Mais Executadas</h1>
      </header>

      <div className="filters">
        <div className="filter-row">
          <div className="filter-group">
            <label htmlFor="ranking-size">Ranking:</label>
            <select id="ranking-size" value={rankingSize} onChange={(e) => setRankingSize(e.target.value)}>
              <option value="10">TOP 10</option>
              <option value="20">TOP 20</option>
              <option value="40">TOP 40</option>
              <option value="100">TOP 100</option>
              <option value="200">TOP 200</option>
            </select>
          </div>

          <div className="filter-group">
            <label htmlFor="radio-select">Rádios:</label>
            <Select
              id="radio-select"
              options={radiosOptions}
              isMulti
              value={selectedRadios}
              onChange={setSelectedRadios}
              placeholder="Selecione as rádios"
              className="react-select-container"
              classNamePrefix="react-select"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </div>
        </div>

        <div className="filter-row-datetime">
          <div className="filter-group">
            <label htmlFor="date-start">Data Início:</label>
            <input
              type="date"
              id="date-start"
              value={dateStart}
              onChange={(e) => setDateStart(e.target.value)}
            />
          </div>

          <div className="filter-group">
            <label htmlFor="date-end">Data Fim:</label>
            <input
              type="date"
              id="date-end"
              value={dateEnd}
              onChange={(e) => setDateEnd(e.target.value)}
            />
          </div>

          <div className="filter-group">
            <label htmlFor="hour-start">Hora Início:</label>
            <input
              type="time"
              id="hour-start"
              value={hourStart}
              onChange={(e) => setHourStart(e.target.value)}
            />
          </div>

          <div className="filter-group">
            <label htmlFor="hour-end">Hora Fim:</label>
            <input
              type="time"
              id="hour-end"
              value={hourEnd}
              onChange={(e) => setHourEnd(e.target.value)}
            />
          </div>
        </div>

        <div className="filter-buttons">
          <button className="btn-primary" onClick={handleSearch} disabled={isLoading}>
            {isLoading ? "Carregando..." : "Pesquisar"}
          </button>
          <button className="btn-secondary" onClick={clearFilters}>Limpar Filtros</button>
        </div>
      </div>

      {errorMessage && <div className="error-message">{errorMessage}</div>}

      {isLoading ? (
        <div className="loading-indicator">
          <div className="spinner"></div>
          <p>Carregando...</p>
        </div>
      ) : (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Rank</th>
                <th></th>
                <th>Artista</th>
                <th>Título</th>
                <th>ISRC</th>
                <th>Gênero</th>
                <th>Execuções</th>
              </tr>
            </thead>
            <tbody>
              {rankingData.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {artistImages[item.artist] ? (
                      <LazyLoadImage
                        src={artistImages[item.artist]}
                        alt={item.artist}
                        effect="blur"
                        width={50}
                        height={50}
                        style={{ borderRadius: "50%" }}
                      />
                    ) : (
                      <div style={{ width: "50px", height: "50px", backgroundColor: "#ddd", borderRadius: "50%" }}></div>
                    )}
                  </td>
                  <td>{item.artist}</td>
                  <td>{item.song_title}</td>
                  <td>{item.isrc}</td>
                  <td>{item.genre}</td>
                  <td>{item.executions}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Ranking;
