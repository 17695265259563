import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: window._env_.REACT_APP_FIREBASE_API_KEY,
  authDomain: window._env_.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: window._env_.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: window._env_.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: window._env_.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: window._env_.REACT_APP_FIREBASE_APP_ID,
};

// Validação das variáveis de ambiente
if (
  !firebaseConfig.apiKey ||
  !firebaseConfig.authDomain ||
  !firebaseConfig.projectId ||
  !firebaseConfig.storageBucket ||
  !firebaseConfig.messagingSenderId ||
  !firebaseConfig.appId
) {
  console.error("Erro: Variáveis de ambiente do Firebase não configuradas corretamente.");
}

// Inicializa o Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

console.log("Firebase inicializado com sucesso!");

export { auth, db };
