import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { getAuth, applyActionCode } from 'firebase/auth';
import './EmailVerification.css';

const EmailVerification = () => {
  const [verificationStatus, setVerificationStatus] = useState('verifying');
  const location = useLocation();
  const auth = getAuth();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const oobCode = queryParams.get('oobCode');

    if (oobCode) {
      applyActionCode(auth, oobCode)
        .then(() => {
          console.log('E-mail verificado com sucesso');
          setVerificationStatus('success');
        })
        .catch((error) => {
          console.error('Erro na verificação:', error);
          setVerificationStatus('error');
        });
    } else {
      setVerificationStatus('error');
    }
  }, [auth, location]);

  return (
    <div className="email-verification-container">
      <div className="email-verification-box">
        {verificationStatus === 'verifying' && (
          <>
            <h2>Verificando seu e-mail...</h2>
            <p>Estamos confirmando tudo para você!</p>
          </>
        )}
        {verificationStatus === 'success' && (
          <>
            <h2>E-mail verificado com sucesso!</h2>
            <p>Seu e-mail foi confirmado. Agora você pode fazer login.</p>
            <Link to="/" className="btn-primary">Fazer Login</Link>
          </>
        )}
        {verificationStatus === 'error' && (
          <>
            <h2>Ops! Algo deu errado.</h2>
            <p>Não conseguimos verificar seu e-mail. O link pode ter expirado ou já foi usado.</p>
            <Link to="/" className="btn-primary">Voltar para o Login</Link>
          </>
        )}
      </div>
    </div>
  );
};

export default EmailVerification;
