import React, { useState, useEffect } from 'react';
import { db, auth } from './firebase';
import { collection, getDoc, getDocs, query, where, addDoc, deleteDoc, doc } from 'firebase/firestore';
import './Admin.css';

const Admin = () => {
  const [excludedSongs, setExcludedSongs] = useState([]);
  const [artist, setArtist] = useState("");
  const [title, setTitle] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      setLoading(true);
      try {
        const user = auth.currentUser;
        if (!user) {
          console.log("Nenhum usuário autenticado");
          setError("Usuário não autenticado. Por favor, faça login.");
          setLoading(false);
          return;
        }

        console.log("UID do usuário:", user.uid);

        // Corrigido: use getDoc em vez de getDocs para um único documento
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
          console.log("Nenhum documento de usuário encontrado");
          setError("Usuário não encontrado no banco de dados.");
          setLoading(false);
          return;
        }

        const userData = userDocSnap.data();
        console.log("Dados do usuário:", userData);

        if (userData.isAdmin) {
          console.log("Usuário é admin");
          setIsAdmin(true);
          await fetchExcludedSongs();
        } else {
          console.log("Usuário não é admin");
          setError("Você não tem permissão de administrador.");
        }
      } catch (error) {
        console.error("Erro completo:", error);
        setError(`Erro ao verificar permissões de administrador: ${error.message}`);
      }
      setLoading(false);
    };

    checkAdminStatus();
  }, []);

  const fetchExcludedSongs = async () => {
    try {
      const excludedSongsSnapshot = await getDocs(collection(db, 'excludedSongs'));
      const excludedSongsData = excludedSongsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setExcludedSongs(excludedSongsData);
    } catch (error) {
      console.error("Erro ao buscar músicas excluídas:", error);
      setError(`Erro ao buscar músicas excluídas: ${error.message}`);
    }
  };

  const handleAddExcludedSong = async (e) => {
    e.preventDefault();
    if (artist && title) {
      try {
        await addDoc(collection(db, 'excludedSongs'), { artist, title });
        setArtist("");
        setTitle("");
        await fetchExcludedSongs();
      } catch (error) {
        console.error("Erro ao adicionar música excluída:", error);
        setError(`Erro ao adicionar música: ${error.message}`);
      }
    }
  };

  const handleRemoveExcludedSong = async (id) => {
    try {
      await deleteDoc(doc(db, 'excludedSongs', id));
      await fetchExcludedSongs();
    } catch (error) {
      console.error("Erro ao remover música excluída:", error);
      setError(`Erro ao remover música: ${error.message}`);
    }
  };

  if (loading) {
    return <div className="admin-container">Carregando...</div>;
  }

  if (error) {
    return <div className="admin-container error">{error}</div>;
  }

  if (!isAdmin) {
    return <div className="admin-container">Acesso negado. Você não tem permissões de administrador.</div>;
  }

  return (
    <div className="admin-container">
      <h2>Admin - Remover Música do Relatório</h2>
      <form onSubmit={handleAddExcludedSong} className="exclude-song-form">
        <div className="form-group">
          <label htmlFor="artist">Artista:</label>
          <input
            type="text"
            id="artist"
            value={artist}
            onChange={(e) => setArtist(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="title">Título:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn-primary">Excluir Música</button>
      </form>

      <h3>MÚSICAS EXCLUÍDAS</h3>
      <ul className="excluded-songs-list">
        {excludedSongs.map((song) => (
          <li key={song.id}>
            {song.artist} - {song.title}
            <button onClick={() => handleRemoveExcludedSong(song.id)} className="btn-remove">Remover</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Admin;
